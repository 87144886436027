@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;1,300;1,400;1,700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

body {
  font-family: "Monserrat", sans-serif;
  margin: 0;
}

h1 {
  font-size: 2.5rem;
  color: #2c292b;
  margin: 1rem 0;
}

h2 {
  font-size: 1.5rem;
  color: #2c292b;
}

a:not([class]) {
  text-decoration: none;
}

.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
