.general {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
}

/*Skills*/
.general__skills {
  list-style: none;
  font-size: 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

@media screen and (max-width: 500px) {
  .general__skills {
    font-size: 1rem;
  }
}

/*Contacts*/

.general__contacts {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
}

@media screen and (max-width: 800px) {
  .general__contacts {
    font-size: 1rem;
  }
}

.general__heading {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  gap: 1rem;
  font-size: 2rem;
  background-color: white;
  align-items: center;
  padding-bottom: 1rem;
}

.general__heading h2 {
  padding: 0 2rem;
}

.general__heading::before,
.general__heading::after {
  content: "";
  height: 1px;
  display: block;
  background-color: black;
}

.general__items {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(2, max-content);
  justify-items: center;
  justify-content: center;
  grid-column-gap: 3rem;
  grid-row-gap: 1em;

  font-size: 1.5rem;
  color: rgb(228, 227, 227);
  text-transform: lowercase;
  margin-bottom: 1rem;
}

@media screen and (max-width: 800px) {
  .general__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
  }
}

/*Languages*/
.general__lang__img {
  height: 7rem;
  width: auto;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
@media screen and (max-width: 800px) {
  .general__lang__img {
    height: 5rem;
  }
}

@media screen and (max-width: 600px) {
  .general__lang__img {
    height: 4rem;
  }
}

.general__lang__container {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 2rem;
}

@media screen and (max-width: 500px) {
  .general__lang__container {
    flex-direction: column;
  }
}

.general__lang {
  display: flex;
  flex-direction: column;
  align-items: center;
}
