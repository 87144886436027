.general__item {
  display: flex;
  gap: 0.5rem;
}

.general__item p {
  color: black;
  text-decoration: none;
}

.general__item svg {
  fill: black;
  height: 1.5rem;
  width: 1.5rem;
  transform: translateY(30%);
}
