.footer {
  width: 100%;
  min-height: 5rem;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgb(43, 43, 43);
  padding: 1rem;
}

.footer p {
  margin: 0;
  color: white;
}
