.button {
  font: inherit;
  border: 1px solid #de1b1b;
  background: #de1b1b;
  color: white;
  padding: 0.5rem 1.5rem;
  cursor: pointer;

  margin: 1rem;
}

@media screen and (max-width: 800px) {
  .button {
    margin: 0.5rem;
  }
}

.button:hover,
.button:active {
  background: #df0707;
  border-color: #df0707;
  transform: scale(1.05);
}

.button:focus {
  outline: none;
}
