.header {
  width: 100%;
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(43, 43, 43);
}

@media screen and (max-width: 800px) {
  .header {
    padding: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .header {
    min-height: 3rem;
    padding: 0.5rem;
  }
}

.logo {
  font-size: 2rem;
  color: white;
  font-weight: bold;
  margin-left: 2rem;
}

@media screen and (max-width: 800px) {
  .logo {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .logo {
    font-size: 1rem;
    margin-left: 1rem;
  }
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin-left: 2rem;
  margin-right: 1rem;
}

@media screen and (max-width: 600px) {
  .header li {
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
}

.header a {
  text-decoration: none;
  font-size: 1.5rem;
}

@media screen and (max-width: 600px) {
  .header a {
    font-size: 1rem;
  }
}

.header a:hover {
  color: rgb(180, 180, 180);
}

.notActivePath {
  color: white;
}

.activePath {
  color: rgb(207, 207, 207);
}
